import React, { useEffect, useState } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'
import MetaData from '../../layout/MetaData'
import AdminLayout from '../../layout/AdminLayout'
import { useCreateComboProductMutation } from '../../../redux/api/comboProductsApi'
import { useGetAdminComboCategoriesQuery } from '../../../redux/api/comboCategoriesApi'

const initialState = {
  name: '',
  price: 0,
  comboProductExtraPrice: 0,
  description: '',
  comboCategory: '',
  stock: 0,
}

const NewComboProduct = () => {
  const [values, setValues] = useState(initialState)
  const {
    name,
    price,
    comboProductExtraPrice,
    description,
    comboCategory,
    stock,
  } = values

  const { data: getComboCategories, error: comboCatError } =
    useGetAdminComboCategoriesQuery()

  const [createComboProduct, { isLoading, error, isSuccess }] =
    useCreateComboProductMutation()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }
    if (comboCatError) {
      toast.error(error?.data?.message)
    }
    if (isSuccess) {
      toast.success('ComboProduct created')
      window.location.reload()
    }
  }, [error, isSuccess, comboCatError])

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    createComboProduct(values)
  }
  if (isLoading) return <Loader />
  return (
    <AdminLayout>
      <MetaData title={'Create new ComboProduct'} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">Create New Combo Product</h2>

            <div className="row">
              <div className="mb-5 col">
                <label htmlFor="combo_category_field" className="form-label">
                  {' '}
                  Combo-Product-Categories{' '}
                </label>
                <select
                  className="form-select"
                  id="combo_category_field"
                  name="comboCategory"
                  value={comboCategory._id}
                  onChange={onChange}
                >
                  <option>Select One</option>
                  {getComboCategories?.comboCategories.map((combo) => (
                    <option key={combo._id} value={combo._id}>
                      {combo.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="name_field" className="form-label">
                {' '}
                Name{' '}
              </label>
              <input
                type="text"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={onChange}
              />
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="I-price_field" className="form-label">
                  {' '}
                  Combo-Product Price{' '}
                </label>
                <input
                  type="number"
                  id="I-price_field"
                  className="form-control"
                  name="price"
                  value={price}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="E-price_field" className="form-label">
                  {' '}
                  Combo-Product Extra-Price{' '}
                </label>
                <input
                  type="number"
                  id="E-price_field"
                  className="form-control"
                  name="comboProductExtraPrice"
                  value={comboProductExtraPrice}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="description_field" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="description_field"
                rows="4"
                name="description"
                value={description}
                onChange={onChange}
              ></textarea>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="stock_field" className="form-label">
                  {' '}
                  Stock{' '}
                </label>
                <input
                  type="number"
                  id="stock_field"
                  className="form-control"
                  name="stock"
                  value={stock}
                  onChange={onChange}
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn w-100 py-2"
              disabled={isLoading}
            >
              {isLoading ? 'Creating...' : 'CREATE'}
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  )
}

export default NewComboProduct
