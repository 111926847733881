import { setIndiviItem } from '../../redux/features/indivItemsSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
const ProductCard = ({ product }) => {
  const iPQuantity = 1


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setItemToIndivi = () => {
    const indiviItem = {
      iPProductId: product?._id,
      iPName: product?.name,
      iPPrice: product?.price,
      iPStock: product?.stock,
      iPQuantity,
      totalCost: iPQuantity * product?.price,
    }

    dispatch(setIndiviItem(indiviItem))
    // navigate(`/mealsdetails`)
    toast.success('Item added to Meal')
  }

  return (
    <>
      <div className="pcard">
        <div className="pcardimg">
          <button onClick={setItemToIndivi}>
            <img
              src={
                product?.images[0]
                  ? product?.images[0]?.url
                  : '/images/default_product.png'
              }
              alt={product?.name}
            />
            Add to Meal
          </button>
        </div>

        <div className=" pcardbody">
          <div className="pcardheader">
            <div className="pcardname">{product?.name?.toUpperCase()}</div>
            <div className="pcardprice">${product?.price?.toFixed(2)}</div>
          </div>
          <div className="pcarddescr">
            Description: {product?.description?.substring(0, 125)}
          </div>

          <hr />
        </div>
      </div>
    </>
  )
}
export default ProductCard
