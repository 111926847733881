import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import MetaData from '../../layout/MetaData'
import AdminLayout from '../../layout/AdminLayout'
import Loader from '../../layout/Loader'
import { useCreateComboMutation } from '../../../redux/api/comboApi'
import { useGetAdminComboCategoriesQuery } from '../../../redux/api/comboCategoriesApi'

const NewCombo = () => {
  const [comboCategory, setComboCategory] = useState([])

  const [combo, setCombo] = useState({
    name: '',
    description: '',
    price: '',
  })

  const { name, description, price } = combo

  const [createCombo, { isLoading, error, isSuccess }] =
    useCreateComboMutation()

  const { data } = useGetAdminComboCategoriesQuery()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }

    if (isSuccess) {
      toast.success('Combo Created')
      window.location.reload()
    }
  }, [error, isSuccess])

  const onChange = (e) => {
    setCombo({ ...combo, [e.target.name]: e.target.value })
  } // handle check for categories
  const handleCheck = (e) => {
    let inTheState = [...comboCategory]
    let justChecked = e.target.value
    let foundInTheState = inTheState.indexOf(justChecked) // index or -1

    // indexOf method ?? if not found returns -1 else return index [1,2,3,4,5]
    if (foundInTheState === -1) {
      inTheState.push(justChecked)
    } else {
      // if found pull out one item from index
      inTheState.splice(foundInTheState, 1)
    }

    setComboCategory(inTheState)

    // ({ category: inTheState })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    createCombo({ combo, comboCategory })
  
  }

  if (isLoading) return <Loader />
  return (
    <AdminLayout>
      <MetaData title={'Create new Combo'} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">New Combo</h2>

            <div className="mb-3">
              <label htmlFor="name_field" className="form-label">
                {' '}
                Name{' '}
              </label>
              <input
                type="text"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={onChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="description_field" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="description_field"
                rows="4"
                name="description"
                value={description}
                onChange={onChange}
              ></textarea>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="price_field" className="form-label">
                  {' '}
                  Price{' '}
                </label>
                <input
                  type="text"
                  id="price_field"
                  className="form-control"
                  name="price"
                  value={price}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="mb-5 col">
                {data?.comboCategories?.map((c, i) => (
                  <div className="form-check" key={i}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="check4"
                      name="comboCategory"
                      value={c._id}
                      onChange={handleCheck}
                      checked={comboCategory.includes(c._id)}
                    />
                    <label for="check4" className="form-check-label">
                      {c.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <button type="submit" className="btn w-100 py-2">
              Create Combo
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  )
}

export default NewCombo
