import { configureStore } from '@reduxjs/toolkit'

import userReducer from './features/userSlice'
import cartReducer from './features/cartSlice'
import comboReducer from './features/comboSlice'
import comboItemReducer from './features/comboItemsSlice'
import mealReducer from './features/mealSlice'
import indiviItemsReducer from './features/indivItemsSlice'
import { productApi } from './api/productsApi'
import { comboProductApi } from './api/comboProductsApi'
import { comboApi } from './api/comboApi'
import { categoryApi } from './api/categoriesApi'
import { comboCategoryApi } from './api/comboCategoriesApi'
import { authApi } from './api/authApi'
import { userApi } from './api/userApi'
import { orderApi } from './api/orderApi'
import { mealApi } from './api/mealApi'

export const store = configureStore({
  reducer: {
    auth: userReducer,
    cart: cartReducer,
    combo: comboReducer,
    comboItem: comboItemReducer,
    indiviItem: indiviItemsReducer,
    meal: mealReducer,

    [productApi.reducerPath]: productApi.reducer,
    [comboProductApi.reducerPath]: comboProductApi.reducer,
    [comboApi.reducerPath]: comboApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [comboCategoryApi.reducerPath]: comboCategoryApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [mealApi.reducerPath]: mealApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      productApi.middleware,
      comboProductApi.middleware,
      categoryApi.middleware,
      comboCategoryApi.middleware,
      comboApi.middleware,
      authApi.middleware,
      userApi.middleware,
      orderApi.middleware,
      mealApi.middleware,
    ]),
})
