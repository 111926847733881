import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../components/layout/Loader'
import { toast } from 'react-hot-toast'
import MetaData from '../components/layout/MetaData'
import { useGetComboDetailsQuery } from '../redux/api/comboApi'
import { useGetFiLteredComboProductsMutation } from '../redux/api/comboProductsApi'
import ComboProductCard from '../components/cards/ComboProductCard'
import { Link } from 'react-router-dom'


const ComboDetails = () => {
  const [productsList, setProductsList] = useState([])

  const params = useParams()

  const [getComboProducts, { error: productsError }] =
    useGetFiLteredComboProductsMutation()
  const {
    data: comboDetails,
    error,
    isLoading,
  } = useGetComboDetailsQuery(params?.id)

  const comboCategory = comboDetails?.combo?.comboCategory

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }
    if (productsError) {
      toast.error(error?.data?.message)
    }
  }, [error, productsError])

  const fetchProducts = (arg) => {
    getComboProducts(arg).then((res) => {
      setProductsList(res.data)
    })
  }
  const handleChange = (e) => {
    let _id = e.target.value
    let comboCat = { _id }
    fetchProducts(comboCat)
  }

  if (isLoading) return <Loader />
  return (
    <>
      <MetaData title={comboDetails?.combo?.name} />

      <h5>Combo Products Selection Page</h5>
      <h6 className="combo-details-h6">
        Select A Category Name For Products List
        <br />
        Then, select the Product you would like
        <br />
        Then, select the next Category
        <br />
        Then, Tap Here{' '}
        <Link to={`/mealsdetails`}>
          <h6>Meal Planning Page</h6>
        </Link>
        to see and change the quantity of products 
   
      </h6>
      <div className=" card-footer list-group-horizontal list-group d-flex justify-content-around ">
        {comboCategory?.map((cat, i) => (
          <div key={i} className=" text-capitalize list-group-item fw-bold">
            <input
              type="radio"
              className="ms-2 me-4"
              name="comboCategory"
              value={cat?._id}
              onChange={handleChange}
            />
            <label className="form-check-label">
              <h5>{cat.name}</h5>
            </label>
          </div>
        ))}
      </div>
      <div>
        {productsList?.comboProducts?.map((product) => (
          <div key={product?._id}>
            <ComboProductCard product={product} />{' '}
          </div>
        ))}
      </div>
    </>
  )
}
export default ComboDetails
