import React, { useEffect, useState } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'

import MetaData from '../../layout/MetaData'
import AdminLayout from '../../layout/AdminLayout'
import { useNavigate, useParams } from 'react-router-dom'

import {

  useGetComboCategoryDetailsQuery,
  useUpdateComboCategoryMutation,
} from '../../../redux/api/comboCategoriesApi'

const UpdateComboCategory = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [comboCategory, setComboCategory] = useState({
    name: '',
  })

  const { name } = comboCategory

  const [updateComboCategory, { isLoading, error, isSuccess }] =
    useUpdateComboCategoryMutation()

  const { data } = useGetComboCategoryDetailsQuery(params?.id)

  useEffect(() => {
    if (data?.comboCategory) {
      setComboCategory({
        name: data?.comboCategory?.name,
      })
    }

    if (error) {
      toast.error(error?.data?.message)
    }

    if (isSuccess) {
      toast.success('ComboCategory updated')
      navigate('/admin/comboCategories')
    }
  }, [error, isSuccess, data])

  const onChange = (e) => {
    setComboCategory({ ...comboCategory, [e.target.name]: e.target.value })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    updateComboCategory({ id: params?.id, body: comboCategory })
  }

  return (
    <AdminLayout>
      <MetaData title={'Update ComboCategory'} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">Update ComboCategory</h2>
            <div className="mb-3">
              <label htmlFor="name_field" className="form-label">
                {' '}
                Name{' '}
              </label>
              <input
                type="text"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={onChange}
              />
            </div>

            <button
              type="submit"
              className="btn w-100 py-2"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'UPDATE'}
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  )
}

export default UpdateComboCategory
