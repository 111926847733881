import React, { useState } from 'react'
import MetaData from '../components/layout/MetaData'
import { v4 as uuidv4 } from 'uuid'
import { Link } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { clearCombo } from '../redux/features/comboSlice'
import { addToCart } from '../redux/features/cartSlice'
import {
  setComboItem,
  clearComboItems,
} from '../redux/features/comboItemsSlice'
import { setIndiviItem, clearIndivi } from '../redux/features/indivItemsSlice'

const MealDetails = () => {
  const [meal, setMeal] = useState({
    name: '',
    message: '',
  })

  const { name, message } = meal

  const dispatch = useDispatch()
  let mealId = uuidv4().substring(28)

  const { combo } = useSelector((state) => state.combo)
  const { comboItems } = useSelector((state) => state.comboItem)
  const { indiviItems } = useSelector((state) => state.indiviItem)

  const comboId = combo?.map((item) => item.comboId)
  const comboName = combo?.map((item) => item.name)
  const comboPrice = combo?.map((item) => Number(item.price))
  const comboDesc = combo?.map((item) => item.description)

  const cProductsTotal = comboItems?.reduce(
    (acc, item) => acc + item?.cPQuantity * item.cPPrice,
    0
  )
  const iProductsTotal = indiviItems?.reduce(
    (acc, item) => acc + item?.iPQuantity * item.iPPrice,
    0
  )

  const mealTotal =
    Number(cProductsTotal) + Number(comboPrice) + Number(iProductsTotal)

  const addComboIQty = (item, cPQuantity) => {
    const newQty = cPQuantity + 1
    if (newQty > item?.cPStock) return
    setItemtoCombo(item, newQty)
  }

  const minusComboIQty = (item, cPQuantity) => {
    const newQty = cPQuantity - 1
    if (newQty <= -1) return
    setItemtoCombo(item, newQty)
  }
  const setItemtoCombo = (item, newQty) => {
    let totalCost
    if (newQty > 0) {
      totalCost = newQty * item?.cPPrice
    }
    const comboItem = {
      catId: item?.catId,
      cPProductId: item?.cPProductId,
      cPName: item?.cPName,
      cPPrice: item?.cPPrice,
      cPStock: item?.cPStock,
      cPQuantity: newQty,
      totalCost,
    }
    dispatch(setComboItem(comboItem))
  }
  const addProductIQty = (item, iPQuantity) => {
    let newQty = iPQuantity + 1
    if (newQty > comboItems?.iPStock) return
    setProductItem(item, newQty)
  }

  const minusProductIQty = (item, iPQuantity) => {
    const newQty = iPQuantity - 1
    if (newQty <= 0) return
    setProductItem(item, newQty)
  }

  const setProductItem = (item, newQty) => {
    let totalCost
    if (newQty > 0) {
      totalCost = newQty * item?.iPPrice
    }
    const indiviItem = {
      iPProductId: item?.iPProductId,
      iPName: item?.iPName,
      iPPrice: item?.iPPrice,
      iPStock: item?.iPStock,
      iPQuantity: newQty,
      totalCost,
    }

    dispatch(setIndiviItem(indiviItem))
  }

  const onChange = (e) => {
    setMeal({ ...meal, [e.target.name]: e.target.value })
  }

  const submitHandler = () => {
    const cartItem = {
      mealId,
      mealTotal,
      name: meal?.name,
      message: meal?.message,
      combo,
      comboItems,
      indiviItems,
      mealQuantity: 1,
    }

    dispatch(addToCart(cartItem))
    dispatch(clearCombo(combo))
    dispatch(clearComboItems(comboItems))
    dispatch(clearIndivi(indiviItems))
    toast.success('Item added to Cart')
  }
  return (
    <>
      <MetaData title={'meal Details'} />
      <div className="m-3">
        <h4>Meal Planning Page</h4>
      </div>
      <div className="headingRow">
        <div> combo Items ${cProductsTotal.toFixed(2)}</div>{' '}
        <div>
          Combo Price <br /> ${Number(comboPrice)?.toFixed(2)}
        </div>
        <div>
          Individual Products <br /> ${Number(iProductsTotal)?.toFixed(2)}
        </div>
        <div>
          Total
          <br />${mealTotal?.toFixed(2)}
        </div>
      </div>
      <div>
        <button
          id="cart_btn"
          className="btn btn-primary d-inline ms-4
       m-2"
        >
          <Link to={`/combos`} className="">
            the Combo List page
          </Link>
        </button>
        <button id="cart_btn" className="btn btn-primary d-inline ms-4 m-2">
          <Link to={`/categories`} className="">
            Individual Products Page
          </Link>
        </button>
      </div>

      <div>
        <form onSubmit={submitHandler}>
          <button
            type="submit"
            id="cart_btn"
            className="btn btn-primary d-inline ms-4 m-2"
            // disabled={product.stock <= 0}
            // onClick={setItemToCart}
          >
            add meal to cart
          </button>
          <label htmlFor="name_field" className="form-label fw-bold">
            Who is this Meal For?
          </label>
          <input
            type="text"
            required
            id="name_field"
            className="form-control"
            placeholder="Enter Name Here"
            name="name"
            value={name}
            onChange={onChange}
          />
          <div className="mb-3">
            <label htmlFor="description_field" className="form-label fw-bold">
              Any Special message About This Meal?
            </label>
            <textarea
              className="form-control"
              id="description_field"
              rows="1"
              name="message"
              placeholder="Enter message Here"
              value={message}
              onChange={onChange}
            ></textarea>
          </div>
        </form>
      </div>

      <h5>combo</h5>

      <div className="comboMealCard">
        <div className="">{comboName}</div>
        <div className="">{comboDesc}</div>
        <div className="">${Number(comboPrice)?.toFixed(2)}</div>
      </div>

      <h5>combo Items</h5>
      <button id="cart_btn" className="btn btn-primary d-inline ms-4 m-2">
        <Link to={`/combo/${comboId}`} className="">
          combo products page
        </Link>
      </button>
      <div></div>

      {comboItems?.map((item, i) => (
        <div key={i}>
          <div className="menuCardBody">
            <div>
              {` ${item?.cPName}
              extra's $${Number(item?.cPPrice)?.toFixed(2)} ea`}
            </div>

            <div>${item?.totalCost?.toFixed(2)}</div>
            <div>
              <span
                className=" btn-minus   "
                onClick={() => minusComboIQty(item, Number(item?.cPQuantity))}
              >
                -
              </span>
              <input
                type="number"
                className="count"
                value={item?.cPQuantity}
                readOnly
              />
              <span
                className="btn-plus "
                onClick={() => addComboIQty(item, Number(item?.cPQuantity))}
              >
                +
              </span>
            </div>
          </div>
        </div>
      ))}

      <div></div>
      <h5>Individual Products</h5>

      {indiviItems?.map((item, i) => (
        <div key={i}>
          <div className="menuCardBody">
            {`  ${item?.iPName}
            ea order $${item?.iPPrice?.toFixed(2)}`}
            <span className="menuCardPrice">
              ${item?.totalCost?.toFixed(2)}
            </span>

            <div className="">
              <span
                className="btn-minus  "
                onClick={() => minusProductIQty(item, item?.iPQuantity)}
              >
                -
              </span>

              <input
                type="number"
                className=" count "
                value={item?.iPQuantity}
                readOnly
              />

              <span
                className="btn-plus"
                onClick={() => addProductIQty(item, item?.iPQuantity)}
              >
                +
              </span>
            </div>
          </div>
        </div>
      ))}
      <hr />
      <div className="fw-bold text-capitalize py-2">
        instructions
      <br />
        {' '}
        3 links to help assemble a meal,
        <br />
        link to: combo list page to choose a combo
        <br />
        upon selecting a combo you will automatically go to the combo products
        page to select your combo products.
        <br />
        after selection of products click link to return to this page "meal
        planning page"
        <br />
        if you would like to increase your orders on the combo products do so
        with the (+) button on each product, the (-) to decrease.
        <br />
        if you would like to change your combo products, click the button that
        says "combo products page"
        <br />
        drinks and additional products are on the "individual products page"
        <br />
        when completed assembling your meal, add your meal to cart. you will not
        be able to make changes to a meal once it is added to cart. you can,
        however, delete it,(remove from cart button), and re-create that meal.
        <br />
        click cart button (on the top row to see your cart,and/or checkout
        <br />
      </div>
    </>
  )
}
export default MealDetails
