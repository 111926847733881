import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import ProductCard from '../components/cards/ProductCard'
import Loader from '../components/layout/Loader'
import { useGetCategoriesQuery } from '../redux/api/categoriesApi'
import { useGetFiLteredProductsMutation } from '../redux/api/productsApi'



const ListCategories = () => {
  const [categoryIds, setCategoryIds] = useState([])
  const [productsList, setProductsList] = useState([])
  const { data: getCategories } = useGetCategoriesQuery()

  const [getFilteredProducts, { error, isError, isLoading }] =
    useGetFiLteredProductsMutation()

  const fetchProducts = (arg) => {
    getFilteredProducts(arg).then((res) => {
      setProductsList(res.data)
    })
  }
  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message)
    }
  }, [isError, error])



  const showCategories = () =>
    getCategories?.categories?.map((c) => (
      <div key={c._id}>
        <input
          type="checkbox"
          onChange={handleCheck}
          className="pb-2 pl-4 pr-4"
          id="check4"
          value={c._id}
          name="category"
          checked={categoryIds.includes(c._id)}
        />
        <label htmlFor="check4" className="form-check-label">
          {c.name}
        </label>
        <br />
      </div>
    ))

  // handle check for categories
  const handleCheck = (e) => {
    let inTheState = [...categoryIds]
    let justChecked = e.target.value
    let foundInTheState = inTheState.indexOf(justChecked) // index or -1

    // indexOf method ?? if not found returns -1 else return index [1,2,3,4,5]
    if (foundInTheState === -1) {
      inTheState.push(justChecked)
    } else {
      // if found pull out one item from index
      inTheState.splice(foundInTheState, 1)
    }

    setCategoryIds(inTheState)
    fetchProducts({ category: inTheState })
  }

  if (isLoading) return <Loader />
  return (
    <>
      <div className="">
        <div>
          <Link to={`/mealsdetails`}>
            <h6>Meal Planning Page</h6>
          </Link>
        </div>
        <div className="">
          <h4>Filter By Categories</h4>
          {showCategories()}
        </div>
        <div className="">
          {productsList?.products?.map((product) => (
            <div key={product?._id}>
              <ProductCard product={product} />{' '}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default ListCategories
