import React, { useEffect } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import MetaData from '../../layout/MetaData'
import AdminLayout from '../../layout/AdminLayout'
import {
  useDeleteOrderMutation,
  useGetAdminOrdersQuery,
} from '../../../redux/api/orderApi'

const ListOrders = () => {
  const { data, isLoading, error } = useGetAdminOrdersQuery()

  const [
    deleteOrder,
    { error: deleteError, isLoading: isDeleteLoading, isSuccess },
  ] = useDeleteOrderMutation()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message)
    }

    if (isSuccess) {
      toast.success('Order Deleted')
    }
  }, [error, deleteError, isSuccess])

  const deleteOrderHandler = (id) => {
    deleteOrder(id)
  }

  if (isLoading) return <Loader />

  return (
    <>
      <AdminLayout>
        <MetaData title={'All Orders'} />

        <h1 className="my-5"> Orders</h1>

        <table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>DATE</th>
              <th>TOTAL</th>
              <th>PAID</th>
              <th>Order Status</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {data?.orders?.map((order) => (
              <tr key={order._id}>
                <td>{order?._id.substring(16)}</td>
                <td>{order?.createdAt.substring(5, 10)}</td>
                <td>${order?.totalAmount.toFixed(2)}</td>
                <td>{order?.paymentInfo?.status?.toUpperCase()}</td>
                <td>{order?.orderStatus}</td>
                <td>
                  <>
                    <Link
                      to={`/admin/orders/${order?._id}`}
                      className="btn btn-outline-primary"
                    >
                      <i className="fa fa-pencil">See Order Details</i>
                    </Link>

                    <button
                      className="btn btn-outline-danger ms-2"
                      onClick={() => deleteOrderHandler(order?._id)}
                      disabled={isDeleteLoading}
                    >
                      <i className="fa fa-trash">Remove</i>
                    </button>
                  </>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </AdminLayout>
    </>
  )
}

export default ListOrders
