import React, { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import Loader from '../../layout/Loader'
import MetaData from '../../layout/MetaData'
import AdminLayout from '../../layout/AdminLayout'
import AdminHeader from '../../layout/AdminHeader'
import {
  useDeleteCategoryMutation,
  useGetAdminCategoriesQuery,
} from '../../../redux/api/categoriesApi'

const ListCategories = () => {
  const { data, isLoading, error } = useGetAdminCategoriesQuery()

  const [
    deleteCategory,
    { isLoading: isDeleteLoading, error: deleteError, isSuccess },
  ] = useDeleteCategoryMutation()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message)
    }

    if (isSuccess) {
      toast.success('Category Deleted')
    }
  }, [error, deleteError, isSuccess])

  const deleteCategoryHandler = (id) => {
    deleteCategory(id)
  }
  if (isLoading) return <Loader />
  return (
    <>
      <AdminLayout>
        <MetaData title={'All Orders'} />
        <div>
          <Link
            to={'/admin/category/new'}
            className="btn btn-outline-dark ms-2"
          >
            <i className="fas fa-plus">
              <h4>Create New Category</h4>
            </i>
          </Link>
        </div>
        <h1 className="my-5"> Category List</h1>
        <div className="row">
          <div className="table-responsive">
            <table className=" table table-success table-bordered border-dark table-striped table-sm align-middle caption-top">
              <thead className="table-dark">
                <tr className="align-middle">
                  <th scope="col" className="text-center">
                    Name
                  </th>
                  <th scope="col" className="text-wrap text-center">
                    Edit Category
                  </th>
                  <th scope="col" className="text-wrap text-center">
                    Delete Category
                  </th>
                </tr>
              </thead>

              <tbody className="table-group-divider">
                {data?.categories?.map((category) => (
                  <tr key={category._id}>
                    <td className="text-center" style={{ width: '20rem' }}>
                      {category?.name?.toUpperCase()}
                    </td>
                    <td className="text-center" style={{ width: '5rem' }}>
                      <Link
                        to={`/admin/categories/${category?._id}`}
                        className="btn btn-outline-primary"
                      >
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                    <td className="text-center" style={{ width: '5rem' }}>
                      <button
                        className="btn btn-outline-danger ms-2"
                        onClick={() => deleteCategoryHandler(category?._id)}
                        disabled={isDeleteLoading}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </>
  )
}

export default ListCategories
