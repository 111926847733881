import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const mealApi = createApi({
  reducerPath: 'mealApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1' }),
  tagTypes: ['Meals', 'Meal'],
  endpoints: (builder) => ({
    getMeals: builder.query({
      query: () => `/mealsget`,
      providesTags: ['Meals'],
    }),

    createMeal: builder.mutation({
      query(body) {
        return {
          url: '/mealscreate',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Meals'],
    }),

    updateMeal: builder.mutation({
      query({ id, body }) {
      
       
        return {
          url: `/mealsupdate/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Meal'],
    }),

    deleteMeal: builder.mutation({
      query(id) {
        return {
          url: `/mealsremove/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['Meals'],
    }),

    getMealDetails: builder.query({
      query: (id) => `/mealsdetails/${id}`,
      providesTags: ['Meal'],
    }),
  }),
})

export const {
  useGetMealDetailsQuery,
  useCreateMealMutation,
  useUpdateMealMutation,
  useDeleteMealMutation,
  useGetMealsQuery,
} = mealApi
