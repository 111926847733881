import React, { useEffect } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'

import { Link } from 'react-router-dom'
import MetaData from '../../layout/MetaData'
import {
  useDeleteProductMutation,
  useGetAdminProductsQuery,
} from '../../../redux/api/productsApi'
import AdminLayout from '../../layout/AdminLayout'

const ListProducts = () => {
  const { data, isLoading, error } = useGetAdminProductsQuery()

  const [
    deleteProduct,
    { isLoading: isDeleteLoading, error: deleteError, isSuccess },
  ] = useDeleteProductMutation()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message)
    }

    if (isSuccess) {
      toast.success('Product Deleted')
    }
  }, [error, deleteError, isSuccess])

  const deleteProductHandler = (id) => {
    deleteProduct(id)
  }

  if (isLoading) return <Loader />

  return (
    <>
      <AdminLayout>
        <MetaData title={'All Products'} />

        <div>
          <Link to={'/admin/product/new'} className="btn btn-outline-dark ms-2">
            <i className="fas fa-plus">
              <h4>Create New Product</h4>
            </i>
          </Link>
        </div>
        <div className="row">
          <br />
          <hr />
          <h6>
            If Stock Setting Is Set To "No", It Will Disable The Order Button
            For That Product
            <br />
            You Can Change The Setting With The Edit Button
          </h6>
          <hr />
          <br />
        </div>
        <div className="table-responsive">
          <table className=" table table-success table-bordered border-dark table-striped table-sm align-middle ">
            <thead className="table-dark">
              <tr className="align-middle">
                <th scope="col" className="text-center">
                  Name
                </th>
                <th scope="col" className="text-center">
                  Price
                </th>
                <th scope="col" className="text-center">
                  Catgeory
                </th>
                <th scope="col" className="text-center">
                  Description
                </th>

                <th scope="col" className="text-center">
                  Stock
                </th>
                <th scope="col" className="text-wrap text-center">
                  Edit Product
                </th>
                <th scope="col" className="text-wrap text-center">
                  Add Images
                </th>
                <th scope="col" className="text-wrap text-center">
                  Delete Product
                </th>
              </tr>
            </thead>

            <tbody className="table-group-divider">
              {data?.products?.map((product) => (
                <tr key={product?._id}>
                  <td className="text-center">
                    {product?.name?.toUpperCase()}
                  </td>
                  <td className="text-center">${product?.price?.toFixed(2)}</td>
                  <td className="text-center">
                    {product?.categoryName?.toUpperCase()}
                  </td>
                  <td className="text-center">
                    {product?.description?.substring(0, 75)}
                  </td>

                  <td className="text-center">{product?.stock}</td>
                  <td className="text-center" style={{ width: '5rem' }}>
                    <Link
                      to={`/admin/products/${product?._id}`}
                      className="btn btn-outline-primary "
                    >
                      <i className="fa fa-pencil p-0"></i>
                    </Link>
                  </td>
                  <td className="text-center" style={{ width: '5rem' }}>
                    {' '}
                    <Link
                      to={`/admin/products/${product?._id}/upload_images`}
                      className="btn btn-outline-success ms-2"
                    >
                      <i className="fa fa-image"></i>
                    </Link>
                  </td>
                  <td className="text-center" style={{ width: '5rem' }}>
                    <button
                      className="btn btn-outline-danger ms-2"
                      onClick={() => deleteProductHandler(product?._id)}
                      disabled={isDeleteLoading}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </AdminLayout>
    </>
  )
}

export default ListProducts
