import React from 'react'
import AdminHeader from './AdminHeader'

const AdminLayout = ({ children }) => {
  const menuItems = [
    {
      name: 'Orders-List',
      url: '/admin/orders',
    },
    {
      name: 'Products-List',
      url: '/admin/products',
    },
    {
      name: 'Categories-List',
      url: '/admin/categories',
    },
    {
      name: 'Combo-Products-List',
      url: '/admin/comboproducts',
    },
    {
      name: 'Combo-List',
      url: '/admin/combos',
    },

    {
      name: 'Combo-Categories List',
      url: '/admin/combocategories',
    },

    {
      name: 'Users-List',
      url: '/admin/users',
    },
  ]

  return (
    <>
      <div>
        <AdminHeader menuItems={menuItems} />
      </div>
      <div className="row col-12 ">{children}</div>
    </>
  )
}

export default AdminLayout
