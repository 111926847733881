import React from 'react'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../auth/ProtectedRoute'
import Dashboard from '../admin/Dashboard'
import ListProducts from '../admin/products/ListProducts'
import NewProduct from '../admin/products/NewProduct'
import UpdateProduct from '../admin/products/UpdateProduct'
import ListComboProducts from '../admin/comboProducts/ListComboProducts'
import NewComboProduct from '../admin/comboProducts/NewComboProduct'
import UpdateComboProduct from '../admin/comboProducts/UpdateComboProduct'
import ListCombos from '../admin/combos/ListCombos'
import NewCombo from '../admin/combos/NewCombo'
import UpdateCombo from '../admin/combos/UpdateCombo'
import ListCategories from '../admin/categories/ListCategories'
import NewCategory from '../admin/categories/NewCategory'
import UpdateCategory from '../admin/categories/UpdateCategory'
import ListComboCategories from '../admin/comboCategories/ListComboCategories'
import NewComboCategory from '../admin/comboCategories/NewComboCategory'
import UpdateComboCategory from '../admin/comboCategories/UpdateComboCategory'
import UploadImages from '../admin/UploadImages'
import ListOrders from '../admin/orders/ListOrders'
import ProcessOrder from '../admin/orders/ProcessOrder'
import ListUsers from '../admin/users/ListUsers'
import UpdateUser from '../admin/users/UpdateUser'
import UploadImagesCombo from '../admin/comboProducts/UploadImagesCombo'


const adminRoutes = () => {
  return (
    <>
      <Route
        path="/admin/dashboard"
        element={
          <ProtectedRoute admin={true}>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/products"
        element={
          <ProtectedRoute admin={true}>
            <ListProducts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/product/new"
        element={
          <ProtectedRoute admin={true}>
            <NewProduct />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/products/:id"
        element={
          <ProtectedRoute admin={true}>
            <UpdateProduct />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/comboproducts"
        element={
          <ProtectedRoute admin={true}>
            <ListComboProducts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/comboproduct/new"
        element={
          <ProtectedRoute admin={true}>
            <NewComboProduct />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/comboproducts/:id"
        element={
          <ProtectedRoute admin={true}>
            <UpdateComboProduct />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/categories"
        element={
          <ProtectedRoute admin={true}>
            <ListCategories />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/category/new"
        element={
          <ProtectedRoute admin={true}>
            <NewCategory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/categories/:id"
        element={
          <ProtectedRoute admin={true}>
            <UpdateCategory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/combocategories"
        element={
          <ProtectedRoute admin={true}>
            <ListComboCategories />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/combocategory/new"
        element={
          <ProtectedRoute admin={true}>
            <NewComboCategory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/combocategories/:id"
        element={
          <ProtectedRoute admin={true}>
            <UpdateComboCategory />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/combos"
        element={
          <ProtectedRoute admin={true}>
            <ListCombos />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/combos/:id"
        element={
          <ProtectedRoute admin={true}>
            <UpdateCombo />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/products/:id/upload_images"
        element={
          <ProtectedRoute admin={true}>
            <UploadImages />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/comboproducts/:id/upload_images"
        element={
          <ProtectedRoute admin={true}>
            <UploadImagesCombo />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/orders"
        element={
          <ProtectedRoute admin={true}>
            <ListOrders />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/orders/:id"
        element={
          <ProtectedRoute admin={true}>
            <ProcessOrder />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/users"
        element={
          <ProtectedRoute admin={true}>
            <ListUsers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/combo/new"
        element={
          <ProtectedRoute admin={true}>
            <NewCombo />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/users/:id"
        element={
          <ProtectedRoute admin={true}>
            <UpdateUser />
          </ProtectedRoute>
        }
      />
    </>
  )
}

export default adminRoutes
