import React, { useEffect } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import MetaData from '../../layout/MetaData'
import AdminLayout from '../../layout/AdminLayout'

import {
  useDeleteUserMutation,
  useGetAdminUsersQuery,
} from '../../../redux/api/userApi'

const ListUsers = () => {
  const { data, isLoading, error } = useGetAdminUsersQuery()

  const [
    deleteUser,
    { error: deleteError, isLoading: isDeleteLoading, isSuccess },
  ] = useDeleteUserMutation()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message)
    }

    if (isSuccess) {
      toast.success('User Deleted')
    }
  }, [error, deleteError, isSuccess])

  const deleteUserHandler = (id) => {
    deleteUser(id)
  }

  if (isLoading) return <Loader />

  return (
    <AdminLayout>
      <MetaData title={'All Users'} />

      <h1 className="my-5">{data?.users?.length} Users</h1>
      <div className="row">
        <div className="table-responsive">
          <table className=" table table-success table-bordered border-dark table-striped table-sm align-middle caption-top">
            <thead className="table-dark">
              <tr className="align-middle">
                <th scope="col" className="text-center">
                  Name
                </th>
                <th scope="col" className="text-center">
                  E-Mail
                </th>
                <th scope="col" className="text-center">
                  Role
                </th>
                <th scope="col" className="text-center">
                  See User Profile
                </th>
                <th scope="col" className="text-center">
                  Delete User
                </th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {data?.users?.map((user) => (
                <tr key={user?._id}>
                  <td className="text-center">{user?.name?.toUpperCase()}</td>
                  <td className="text-center">{user?.email}</td>
                  <td className="text-center">{user?.role.toUpperCase()}</td>
                  <td className="text-center" style={{ width: '5rem' }}>
                    <Link
                      to={`/admin/users/${user?._id}`}
                      className="btn btn-outline-primary"
                    >
                      <i className="fa fa-pencil"></i>
                    </Link>
                  </td>
                  <td className="text-center" style={{ width: '5rem' }}>
                    <button
                      className="btn btn-outline-danger ms-2"
                      onClick={() => deleteUserHandler(user?._id)}
                      disabled={isDeleteLoading}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  )
}

export default ListUsers
