export const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2)
}

export const caluclateOrderCost = (cartItems) => {
  let itemsPrice = cartItems?.reduce(
    (acc, item) => acc + (item.mealTotal * 100) / 100,
    0
  )
  itemsPrice = addDecimals(itemsPrice)
 
  let taxPrice = 0.15 * itemsPrice
  taxPrice = addDecimals(taxPrice)

  let totalPrice = Number(itemsPrice) + Number(taxPrice)
  totalPrice = addDecimals(totalPrice)
  console.log(totalPrice)
  return {
    itemsPrice,
    taxPrice,
    totalPrice,
  }
}
