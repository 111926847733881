import ProductList from '../components/product/ProductList'

const Home = () => {
  return (
    <div>
      To create an Order:
      <br />
      An Order is EveryThing that is Included in One Payment.
      <br />
      To start, go to the Meal Planning Page (see Top row.).
      <br />
      On the top row(NavBar) is The link to The Meal Planning Page.
      <br />
      On this page is Links to the combo's and individual products categories.
      <br />
      clicking the individual products categories gives you those products.
      <br />
      create a meal by adding your selections to the meal. You can make changes
      as you do so, and also add extra servings on your combo if wanted.
      <br />
      A name will be asked for as to who this meal is for.
      <br />
      Add meal to cart when completed.
      <br />
      The Meal Planning Page will be cleared of all information so you can
      create the next meal.
      <br />
      click on the cart link on the navbar to see how your preview your order
      befor paying.
      <br />
      if you need to make changes to a meal you need to remove that meal from
      cart, return to the meal planning page and re-create that meal.
      <ProductList />
    </div>
  )
}
export default Home
