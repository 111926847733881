import { Route } from 'react-router-dom'
import ProductDetails from '../product/ProductDetails'
import CategoriesList from '../../pages/CategoriesList'
import ComboList from '../../pages/ComboList'
import ComboDetails from '../../pages/ComboDetails'
import Login from '../auth/Login'
import Register from '../auth/Register'
import Profile from '../user/Profile'
import UpdateProfile from '../user/UpdateProfile'
import ProtectedRoute from '../auth/ProtectedRoute'
import UpdatePassword from '../user/UpdatePassword'
import ForgotPassword from '../auth/ForgotPassword'
import ResetPassword from '../auth/ResetPassword'
import Cart from '../../pages/Cart'

import MyOrders from '../../pages/MyOrders'

import Home from '../../pages/Home'

import Checkboxes from '../filters/Checkboxes'

import MealDetails from '../../pages/MealDetails'

const userRoutes = () => {
  return (
    <>
      <Route path="/" element={<Home />} />

      <Route path="/product/:id" element={<ProductDetails />} />

      <Route path="/categories" element={<CategoriesList />} />
      <Route path="/combo/:id" element={<ComboDetails />} />
      <Route path="/combos" element={<ComboList />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/checkboxes" element={<Checkboxes />} />
      <Route path="/password/forgot" element={<ForgotPassword />} />
      <Route path="/password/reset/:token" element={<ResetPassword />} />

      <Route path="/mealsdetails" element={<MealDetails />} />

      <Route
        path="/me/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />

      <Route
        path="/me/update_profile"
        element={
          <ProtectedRoute>
            <UpdateProfile />
          </ProtectedRoute>
        }
      />

      <Route
        path="/me/update_password"
        element={
          <ProtectedRoute>
            <UpdatePassword />
          </ProtectedRoute>
        }
      />

      <Route path="/cart" element={<Cart />} />

      <Route
        path="/me/orders"
        element={
          <ProtectedRoute>
            <MyOrders />
          </ProtectedRoute>
        }
      />
    </>
  )
}

export default userRoutes
