import React, { useEffect } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import MetaData from '../../layout/MetaData'
import {
  useDeleteComboMutation,
  useGetAdminCombosQuery,
} from '../../../redux/api/comboApi'
import AdminLayout from '../../layout/AdminLayout'

const ListCombos = () => {
  const { data, isLoading, error } = useGetAdminCombosQuery()

  const [
    deleteCombo,
    { isLoading: isDeleteLoading, error: deleteError, isSuccess },
  ] = useDeleteComboMutation()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message)
    }

    if (isSuccess) {
      toast.success('Combo Deleted')
    }
  }, [error, deleteError, isSuccess])

  const deleteComboHandler = (id) => {
    deleteCombo(id)
  }
  if (isLoading) return <Loader />

  return (
    <>
      <AdminLayout>
        <MetaData title={'Combos'} />

        <div className="m-2">
          <Link to={'/admin/combo/new'} className="btn btn-outline-dark ms-2">
            <i className="fas fa-plus">
              <h4>Create New Combo</h4>
            </i>
          </Link>
        </div>
        <h3 className="my-5">Combo List</h3>
        <div className="row">
          <div className="table-responsive">
            <table className=" table table-success table-bordered border-dark table-striped table-sm align-middle caption-top">
              <thead className="table-dark">
                <tr className="align-middle">
                  <th scope="col" className="text-center">
                    Name
                  </th>
                  <th scope="col" className="text-center">
                    Price
                  </th>
                  <th scope="col" className="text-center">
                    Description
                  </th>

                  <th scope="col" className="text-wrap text-center">
                    Edit Combo
                  </th>
                  <th scope="col" className="text-wrap text-center">
                    Delete Combo
                  </th>
                </tr>
              </thead>

              <tbody className="table-group-divider">
                {data?.combos?.map((combo) => (
                  <tr key={combo._id}>
                    <td className="text-center" style={{ width: '20rem' }}>
                      {combo?.name}
                    </td>
                    <td className="text-center" style={{ width: '20rem' }}>
                      ${combo?.price?.toFixed(2)}
                    </td>
                    <td className="text-center" style={{ width: '20rem' }}>
                      {combo?.description}
                    </td>

                    <td className="text-center" style={{ width: '5rem' }}>
                      <Link
                        to={`/admin/combos/${combo?._id}`}
                        className="btn btn-outline-primary"
                      >
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                    <td className="text-center" style={{ width: '5rem' }}>
                      <button
                        className="btn btn-outline-danger ms-2"
                        onClick={() => deleteComboHandler(combo?._id)}
                        disabled={isDeleteLoading}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </>
  )
}

export default ListCombos
