import React, { useEffect } from 'react'
import Loader from '../components/layout/Loader'
import { toast } from 'react-hot-toast'
import MetaData from '../components/layout/MetaData'
import { useGetCombosQuery } from '../redux/api/comboApi'
import ComboCard from '../components/cards/ComboCard'
import { Link } from 'react-router-dom'
const ComboList = () => {
  const { data, isLoading, error } = useGetCombosQuery()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }
  }, [error])

  if (isLoading) return <Loader />
  return (
    <>
      <MetaData title={"Combo's"} />
      <h3>Combo List</h3>
      <div>
        <Link to={`/mealsdetails`}>
          <h6>Meal Planning Page</h6>
        </Link>
      </div>
      <div>
        {data?.combos?.map((combo) => (
          <div key={combo?._id}>
            <ComboCard combo={combo} />{' '}
          </div>
        ))}
      </div>
    </>
  )
}

export default ComboList
