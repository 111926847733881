import React, { useEffect } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'

import { Link } from 'react-router-dom'
import MetaData from '../../layout/MetaData'
import {
  useDeleteComboProductMutation,
  useGetAdminComboProductsQuery,
} from '../../../redux/api/comboProductsApi'
import AdminLayout from '../../layout/AdminLayout'

const ListComboProducts = () => {
  const { data, isLoading, error } = useGetAdminComboProductsQuery()

  const [
    deleteComboProduct,
    { isLoading: isDeleteLoading, error: deleteError, isSuccess },
  ] = useDeleteComboProductMutation()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message)
    }

    if (isSuccess) {
      toast.success('ComboProduct Deleted')
    }
  }, [error, deleteError, isSuccess])

  const deleteComboProductHandler = (id) => {
    deleteComboProduct(id)
  }

  if (isLoading) return <Loader />

  return (
    <>
      <AdminLayout>
        <MetaData title={'All ComboProducts'} />

        <div className="m-2">
          <Link
            to={'/admin/comboProduct/new'}
            className="btn btn-outline-dark ms-2"
          >
            <i className="fas fa-plus">
              <h4>Create New ComboProduct</h4>
            </i>
          </Link>
        </div>
        <div className="row">
          <br />
          <h6 className="m-2">
            If Stock Quantity=0, It Will Disable The Order Button For That
            Product
            <br />
            You Can Change The Setting Within The Edit Function
          </h6>
          <br />
        </div>
        <div className="row">
          <div className="table-responsive">
            <table className=" table table-success table-bordered border-dark table-striped table-sm align-middle ">
              <thead className="table-dark">
                <tr className="align-middle">
                  <th scope="col" className="text-center">
                    Name
                  </th>
                  <th scope="col" className="text-center">
                    Price
                  </th>
                  <th scope="col" className="text-center">
                    Ex-Price
                  </th>
                  <th scope="col" className="text-center">
                    Category
                  </th>
                  <th scope="col" className="text-center">
                    Description
                  </th>
                  <th scope="col" className="text-center">
                    Stock
                  </th>
                  <th scope="col" className="text-wrap text-center">
                    Edit ComboProduct
                  </th>
                  <th scope="col" className="text-wrap text-center">
                    Add Images
                  </th>
                  <th scope="col" className="text-wrap text-center">
                    Delete ComboProduct
                  </th>
                </tr>
              </thead>

              <tbody className="table-group-divider">
                {data?.comboProducts?.map((comboProduct) => (
                  <tr key={comboProduct?._id}>
                    <td className="text-center">
                      {comboProduct?.name?.toUpperCase()}
                    </td>
                    <td className="text-center">${comboProduct?.price}</td>
                    <td className="text-center">
                      ${comboProduct?.comboProductExtraPrice}
                    </td>
                    <td className="text-center">
                      {comboProduct?.comboCategory?.name?.toUpperCase()}
                    </td>
                    <td className="text-center">{comboProduct?.description}</td>

                    <td className="text-center">{comboProduct?.stock}</td>
                    <td className="text-center" style={{ width: '5rem' }}>
                      <Link
                        to={`/admin/comboProducts/${comboProduct?._id}`}
                        className="btn btn-outline-primary "
                      >
                        <i className="fa fa-pencil p-0"></i>
                      </Link>
                    </td>
                    <td className="text-center" style={{ width: '5rem' }}>
                      {' '}
                      <Link
                        to={`/admin/comboProducts/${comboProduct?._id}/upload_images`}
                        className="btn btn-outline-success ms-2"
                      >
                        <i className="fa fa-image"></i>
                      </Link>
                    </td>
                    <td className="text-center" style={{ width: '5rem' }}>
                      <button
                        className="btn btn-outline-danger ms-2"
                        onClick={() =>
                          deleteComboProductHandler(comboProduct?._id)
                        }
                        disabled={isDeleteLoading}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </>
  )
}

export default ListComboProducts
