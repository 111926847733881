import React, { useEffect, useState } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'

import MetaData from '../../layout/MetaData'
import AdminLayout from '../../layout/AdminLayout'
import { useNavigate, useParams } from 'react-router-dom'

import {

  useGetComboDetailsQuery,
  useUpdateComboMutation,
} from '../../../redux/api/comboApi'

const UpdateCombo = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [combo, setCombo] = useState({
    name: '',
    description: '',
    price: '',
  })

  const { name, description, price } = combo

  const [updateCombo, { isLoading, error, isSuccess }] =
    useUpdateComboMutation()

  const { data } = useGetComboDetailsQuery(params?.id)

  useEffect(() => {
    if (data?.combo) {
      setCombo({
        name: data?.combo?.name,
        description: data?.combo?.description,
        price: data?.combo?.price,
      })
    }

    if (error) {
      toast.error(error?.data?.message)
    }

    if (isSuccess) {
      toast.success('Combo updated')
      navigate('/admin/combos')
    }
  }, [error, isSuccess, data])

  const onChange = (e) => {
    setCombo({ ...combo, [e.target.name]: e.target.value })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    updateCombo({ id: params?.id, body: combo })
  }
  if (isLoading) return <Loader />
  return (
    <AdminLayout>
      <MetaData title={'Update Combo'} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">Update Combo</h2>
            <div className="mb-3">
              <label htmlFor="name_field" className="form-label">
                {' '}
                Name{' '}
              </label>
              <input
                type="text"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={onChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="description_field" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="description_field"
                rows="4"
                name="description"
                value={description}
                onChange={onChange}
              ></textarea>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="price_field" className="form-label">
                  {' '}
                  Price{' '}
                </label>
                <input
                  type="text"
                  id="price_field"
                  className="form-control"
                  name="price"
                  value={price}
                  onChange={onChange}
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn w-100 py-2"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'UPDATE'}
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  )
}

export default UpdateCombo
