import { setComboItem } from '../../redux/features/comboItemsSlice'

import { useDispatch } from 'react-redux'
import { toast } from 'react-hot-toast'

const ComboProductCard = ({ product }) => {
  let quantity = 0
 
  let catId = product?.comboCategory?._id

  const dispatch = useDispatch()

  const setItemToMeal = () => {
    const mealItem = {
      catId,
      cPProductId: product?._id,
      cPName: product?.name,
      cPPrice: product?.comboProductExtraPrice,
      cPStock: product?.stock,
      cPQuantity: quantity,
      totalCost:0
    }
    dispatch(setComboItem(mealItem))
    // window.location.reload()
    toast.success('Item added to Combo')
  }

  return (
    <>
      <div className="pcard">
        <div className="pcardimg">
          <button onClick={setItemToMeal}>
            <img
              src={
                product?.images[0]
                  ? product?.images[0]?.url
                  : '/images/default_product.png'
              }
              alt={product?.name}
            />
            Add to Meal
          </button>
        </div>

        <div className=" pcardbody">
          <div className="pcardheader">
            <div className="pcardname">{product?.name.toUpperCase()}</div>
            <div>${product?.comboProductExtraPrice?.toFixed(2)}</div>
          </div>
          <div className="pcarddescr">
            Description: {product?.description?.substring(0, 125)}{' '}
          </div>
        </div>
      </div>
    </>
  )
}
export default ComboProductCard
