import './App.css'
import { BrowserRouter as Router, Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import Footer from './components/layout/Footer'
import Navbar from './components/layout/Navbar'
import { Toaster } from 'react-hot-toast'
import useUserRoutes from './components/routes/userRoutes'
import useAdminRoutes from './components/routes/adminRoutes'
import NotFound from './components/layout/NotFound'

function App() {
  const userRoutes = useUserRoutes()
  const adminRoutes = useAdminRoutes()

  return (
    <Router>
      <div className="App">
        <Toaster position="top-center" />
        <Navbar />

        <div className="container">
     
          <Routes>
            {userRoutes}
            {adminRoutes}
            <Route path="*" element={NotFound} />
          </Routes>
        </div>

        <Footer />
      </div>
    </Router>
  )
}

export default App
