import React, { useState } from 'react'

const Checkboxes = ({ categories, handleFilters }) => {
  const [checked, setChecked] = useState([])

  const handleToggle = (c) => () => {
    //return the first index of -1
    const currentCategoryId = checked.indexOf(c)
    const newCheckedCategoryId = [...checked]
    //if currently checked is not already in checked state > push
    // else pull/remove
    if (currentCategoryId === -1) {
      newCheckedCategoryId.push(c)
    } else {
      newCheckedCategoryId.splice(currentCategoryId, 1)
    }

    setChecked(newCheckedCategoryId)
    // handleFilters(newCheckedCategoryId)
  }

  return (
    <>
      <div>
        {categories?.map((c, i) => (
          <li key={i} className="list-unstyled">
            <input
              type="checkbox"
              className="form-check-input"
              value={checked.indexOf(c._id === -1)}
              onChange={handleToggle(c._id)}
            />
            <label className="form-check-label">{c?.name}</label>
          </li>
        ))}
      </div>
    </>
  )
}
export default Checkboxes
