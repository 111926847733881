import { setCombo } from '../../redux/features/comboSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
// import { toast } from 'react-hot-toast'

const ComboCard = ({ combo }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setItemToCombo = () => {
    const comboUnit = {
      comboId: combo?._id,
      name: combo?.name,
      price: combo?.price,
      description: combo?.description,

    }
    dispatch(setCombo(comboUnit))
   
    navigate(`/combo/${combo?._id}`)
  }
  return (
    <>
      <div className="combocard">
        <div className="combocardimg">
          <button
            // type="button"
            // id="cart_btn"
            // className="btn btn-primary "
            onClick={setItemToCombo}
          >
            Add Combo To Meal Plan
          </button>
        </div>
        <div className=" combocardbody">
          <div className="combocardheader">
            <div className="combocardname">{combo?.name?.toUpperCase()}</div>
            <div className="combocardprice">
              Price: ${combo?.price?.toFixed(2)}
              <div className="combocarddescr">
                Includes:
                <br />
                {combo?.description?.substring(0, 225)}{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ComboCard
