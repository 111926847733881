import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mealItems: localStorage.getItem('mealItems')
    ? JSON.parse(localStorage.getItem('mealItems'))
    : [],
}

export const mealSlice = createSlice({
  initialState,
  name: 'mealSlice',
  reducers: {
    setMealItem: (state, action) => {
      const item = action.payload

      const isItemExist = state.mealItems.find((i) => i.mealId === item.mealId)

      if (isItemExist) {
        state.mealItems = state.mealItems.map((i) =>
          i.mealId === isItemExist.mealId ? item : i
        )
      } else {
        state.mealItems = [...state.mealItems, item]
      }

      localStorage.setItem('mealItems', JSON.stringify(state.mealItems))
    },
    removeMealItem: (state, action) => {
      state.mealItems = state?.mealItems?.filter(
        (i) => i.product !== action.payload
      )

      localStorage.setItem('mealItems', JSON.stringify(state.mealItems))
    },
    clearMeal: (state, action) => {
      localStorage.removeItem('mealItems')
      state.mealItems = []
    },
  },
})

export default mealSlice.reducer

export const { setMealItem, removeMealItem, clearMeal } = mealSlice.actions
