import React from 'react'
import { useGetMeQuery } from '../../redux/api/userApi'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useLazyLogoutQuery } from '../../redux/api/authApi'

const Navbar = () => {
  const navigate = useNavigate()
 
  const { isLoading } = useGetMeQuery()
  const [logout] = useLazyLogoutQuery()

  const { user } = useSelector((state) => state.auth)
  const { cartItems } = useSelector((state) => state.cart)

  const logoutHandler = () => {
    logout()
  
    navigate(0)
  }

  return (
    <nav className="navbar row ">
      <div className="nav-center col-12 col-md-3 mt-4 mt-md-0 ">
        <div className="navbar-brand ">
          <a href="/">
            <h6>Home Page</h6>
          </a>
        </div>

        <div>
          <Link to={`/mealsdetails`}>
            <h6>Meal Planning Page</h6>
          </Link>
        </div>
        <div>
          <a href="/cart" style={{ textDecoration: 'none' }}>
            <span id="cart" className="ms-3">
              {' '}
              <h6> Cart </h6>
            </span>
            <span className="ms-1" id="cart_count">
              {cartItems?.length}
            </span>
          </a>
        </div>

        <div>
          {user ? (
            <div className="ms-4 dropdown">
              <button
                className="btn dropdown-toggle text-white"
                type="button"
                id="dropDownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{user?.name}</span>
              </button>
              <div
                className="dropdown-menu w-100"
                aria-labelledby="dropDownMenuButton"
              >
                {user?.role === 'admin' && (
                  <Link className="dropdown-item" to="/admin/dashboard">
                    {' '}
                    Dashboard{' '}
                  </Link>
                )}

                <Link className="dropdown-item" to="/me/orders">
                  {' '}
                  Orders{' '}
                </Link>

                <Link className="dropdown-item" to="/me/profile">
                  {' '}
                  Profile{' '}
                </Link>

                <Link
                  className="dropdown-item text-danger"
                  to="/"
                  onClick={logoutHandler}
                >
                  Logout{' '}
                </Link>
              </div>
            </div>
          ) : (
            !isLoading && (
              <Link to="/login" className="btn ms-4" id="login_btn">
                {' '}
                Login{' '}
              </Link>
            )
          )}
        </div>
      </div>
    </nav>
  )
}
export default Navbar
