import AdminLayout from '../layout/AdminLayout'

import MetaData from '../layout/MetaData'

const Dashboard = () => {
  return (
    <>
      <AdminLayout>
        <MetaData title={'Admin Products'} />

        <div className="mb-5">

        </div>
      </AdminLayout>
    </>
  )
}

export default Dashboard
