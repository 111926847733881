import React, { useEffect } from 'react'
import { toast } from 'react-hot-toast'
// import { Link } from 'react-router-dom'
import Loader from '../../components/layout/Loader'
import MetaData from '../../components/layout/MetaData'
import { useGetProductsQuery } from '../../redux/api/productsApi'

const ListProducts = () => {
  const { data, isLoading, error } = useGetProductsQuery()



  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }



  }, [error, ])



  if (isLoading) return <Loader />

  return (
    <>
      <MetaData title={'All Products'} />

      <div className="row">
        <br />
        <hr />
        {data?.products?.map((product) => (
        <>
          <div className="pcard">
            <div className="pcardimg">
           
                <img
                  src={
                    product?.images[0]
                      ? product?.images[0]?.url
                      : '/images/default_product.png'
                  }
                  alt={product?.name}
                />

            </div>

            <div className=" pcardbody">
              <div className="pcardheader">
                <div className="pcardname">{product?.name?.toUpperCase()}</div>
                <div className="pcardprice">${product?.price?.toFixed(2)}</div>
              </div>
              <div className="pcarddescr">
                Description: {product?.description?.substring(0, 125)}
              </div>

              <hr />
            </div>
          </div>
    
        <hr />
            <br />
            </>
         ))} 
      </div>
 
    </>
  )
}

export default ListProducts
