import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const comboProductApi = createApi({
  reducerPath: 'comboProductApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1' }),
  tagTypes: ['ComboProduct', 'AdminComboProducts'],
  endpoints: (builder) => ({
    getAdminComboProducts: builder.query({
      query: () => `/admin/comboproducts`,
      providesTags: ['AdminComboProducts'],
    }),

    createComboProduct: builder.mutation({
      query(body) {
        return {
          url: '/admin/comboproducts',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['AdminComboProducts'],
    }),

    updateComboProduct: builder.mutation({
      query({ id, body }) {
        return {
          url: `/admin/comboproducts/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['ComboProduct', 'AdminComboProducts'],
    }),

    deleteComboProduct: builder.mutation({
      query(id) {
        return {
          url: `/admin/comboproducts/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['AdminComboProducts'],
    }),

    uploadComboProductImages: builder.mutation({
      query({ id, body }) {
        return {
          url: `/admin/comboproducts/${id}/upload_images`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['ComboProduct'],
    }),

    deleteComboProductImage: builder.mutation({
      query({ id, body }) {
        return {
          url: `/admin/comboproducts/${id}/delete_image`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['ComboProduct'],
    }),

    getComboProducts: builder.query({
      query: (params) => ({
        url: '/comboproducts',
        params: {
          page: params?.page,
          category: params?.category,
        },
      }),
    }),

    getComboProductDetails: builder.query({
      query: (id) => `/comboproducts/${id}`,
      providesTags: ['ComboProduct'],
    }),

    getFiLteredComboProducts: builder.mutation({
      query(body) {
        return {
          url: '/comboProducts/combo',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['ComboProducts'],
    }),
  }),
})

export const {
  useGetComboProductsQuery,
  useGetFiLteredComboProductsMutation,
  useGetComboProductDetailsQuery,
  useGetAdminComboProductsQuery,
  useCreateComboProductMutation,
  useUpdateComboProductMutation,
  useUploadComboProductImagesMutation,
  useDeleteComboProductImageMutation,
  useDeleteComboProductMutation,
} = comboProductApi
