import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const comboCategoryApi = createApi({
  reducerPath: 'comboCategoryApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1' }),
  tagTypes: ['ComboCategories', 'ComboCategory', 'AdminComboCategories'],
  endpoints: (builder) => ({
    getAdminComboCategories: builder.query({
      query: () => `/admin/comboCategories`,
      providesTags: ['AdminComboCategories'],
    }),

    createComboCategory: builder.mutation({
      query(body) {
        return {
          url: '/admin/comboCategories',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['AdminComboCategories'],
    }),

    updateComboCategory: builder.mutation({
      query({ id, body }) {
        return {
          url: `/admin/comboCategories/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['ComboCategory', 'AdminComboCategories'],
    }),

    deleteComboCategory: builder.mutation({
      query(id) {
        return {
          url: `/admin/comboCategories/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['AdminComboCategories'],
    }),

    getComboCategories: builder.query({
      query: () => `/comboCategories`,
      providesTags: ['ComboCategories'],
    }),
    getComboCategoryDetails: builder.query({
      query: (id) => `/comboCategories/${id}`,
      providesTags: ['ComboCategory'],
    }),
  }),
})

export const {
  useGetComboCategoryDetailsQuery,
  useCreateComboCategoryMutation,
  useUpdateComboCategoryMutation,
  useDeleteComboCategoryMutation,
  useGetAdminComboCategoriesQuery,
  useGetComboCategoriesQuery,
} = comboCategoryApi
