import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  comboItems: localStorage.getItem('comboItems')
    ? JSON.parse(localStorage.getItem('comboItems'))
    : [],
}

export const comboItemsSlice = createSlice({
  initialState,
  name: 'comboItemsSlice',
  reducers: {
    setComboItem: (state, action) => {
      const item = action.payload

      const isItemExist = state.comboItems.find((i) => i.catId === item.catId)

      if (isItemExist) {
        state.comboItems = state.comboItems.map((i) =>
          i.catId === isItemExist.catId ? item : i
        )
      } else {
        state.comboItems = [...state.comboItems, item]
      }

      localStorage.setItem('comboItems', JSON.stringify(state.comboItems))
    },
    removeComboItem: (state, action) => {
      state.comboItems = state?.comboItems?.filter(
        (i) => i.product !== action.payload
      )

      localStorage.setItem('comboItems', JSON.stringify(state.comboItems))
    },
    clearComboItems: (state, action) => {
      localStorage.removeItem('comboItems')
      state.comboItems = []
    },
  },
})

export default comboItemsSlice.reducer

export const { setComboItem, removeComboItem, clearComboItems } = comboItemsSlice.actions
