import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1' }),
  tagTypes: ['Categories', 'Category', 'AdminCategories'],
  endpoints: (builder) => ({
    getAdminCategories: builder.query({
      query: () => `/admin/categories`,
      providesTags: ['AdminCategories'],
    }),

    getCategories: builder.query({
      query: () => `/categories`,
      providesTags: ['Categories'],
    }),

    createCategory: builder.mutation({
      query(body) {
        return {
          url: '/admin/categories',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['AdminCategories'],
    }),

    updateCategory: builder.mutation({
      query({ id, body }) {
        return {
          url: `/admin/categories/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Category', 'AdminCategories'],
    }),

    deleteCategory: builder.mutation({
      query(id) {
        return {
          url: `/admin/categories/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['AdminCategories'],
    }),

    getCategoryDetails: builder.query({
      query: (id) => `/categories/${id}`,
      providesTags: ['Category'],
    }),
  }),
})

export const {
  useGetCategoryDetailsQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useGetAdminCategoriesQuery,
  useGetCategoriesQuery,
} = categoryApi
