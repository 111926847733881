import React, { useEffect } from 'react'
import { useMyOrdersQuery } from '../redux/api/orderApi'
import Loader from '../components/layout/Loader'
import { toast } from 'react-hot-toast'
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom'
import MetaData from '../components/layout/MetaData'
import { useDispatch } from 'react-redux'
import { clearCartItems } from '../redux/features/cartSlice'

const MyOrders = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const orderSuccess = searchParams.get('order_success')

  const { data, isLoading, error } = useMyOrdersQuery()


  console.log('orderData', data)
  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }

    if (orderSuccess) {
      dispatch(clearCartItems())
      navigate('/me/orders')
    }
  }, [error, orderSuccess])

  if (isLoading) return <Loader />

  return (
    <>
      <MetaData title={'My Orders'} />
      {/* <h1 className="my-5">{data?.orders?.length} Orders</h1> */}

      {/* Header Row */}
      {data?.orders?.map((item) => (
        <div className="order-card">
          <div className="heading">
            <div className="heading-col">
              <div className="cart-meal-row status">
                order status<span>{item?.orderStatus}</span>
              </div>
              <div className="cart-meal-row">
                userName<span>{item?.user?.name}</span>
              </div>

              <div className="cart-meal-row">
                user email <span> {item?.user?.email}</span>
              </div>
              <div className="cart-meal-row">
                orderId <span> {item._id?.substring(16)}</span>
              </div>
              <div className="cart-meal-row">
                ordered date-time <span>{moment(item.createdAt)?.format('LLL')}</span>
              </div>
            </div>
            {/* total column */}
            <div className="total-col">
              <div className="cart-meal-row">
                Sub-Total <span>${item?.itemsPrice?.toFixed(2)}</span>
              </div>
              <div className="cart-meal-row">
                Tax <span>${item?.taxAmount?.toFixed(2)}</span>
              </div>
              <div className="cart-meal-row">
                Total <span>${item?.totalAmount?.toFixed(2)}</span>
              </div>
            </div>
          </div>
          <div>
            <h5>meals</h5>

            <>
              {item?.orderItems?.map((item1) => (
                <div>
                  <div className="cart-meal-row">
                    name <span>{item1?.name}</span>
                  </div>
                  <div className="cart-meal-row">
                    price: <span>${Number(item1?.mealTotal)?.toFixed(2)}</span>
                  </div>
                  <div className="cart-meal-row">
                    id#: <span>{item1?.mealId}</span>
                  </div>
                  {/* combo */}
                  {item1?.combo?.length > 0 && (
                    <div>
                      <h5>combo</h5>
                      {item1?.combo?.map((item2) => (
                        <>
                          <div className="cart-meal-row">
                            <div>{item2?.name}</div>
                            <div> cost is ${item2?.price?.toFixed(2)}</div>
                          </div>
                        </>
                      ))}

                      <h5>Combo Products</h5>
                      {/* comboItem */}
                      {item1?.comboItems?.map((item3) => (
                        <div className="cart-meal-row">
                          <div>
                            {`${item3?.cPName} ${
                              item3?.cPQuantity
                            } extra's @ $${item3?.cPPrice?.toFixed(2)}`}
                          </div>
                          <div>
                            {` Cost is
                      $${item3?.totalCost?.toFixed(2)}`}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <h5>Individual Products</h5>
                  {/* products */}
                  {item1?.indiviItems?.map((item4) => (
                    <div className="cart-meal-row">
                      <div>
                        {` ${item4?.iPQuantity} order ${item4?.iPName}

                       @ 
                      $${item4?.iPPrice?.toFixed(2)}`}
                      </div>
                      <div>{`cost is $${item4?.totalCost?.toFixed(2)}`}</div>
                    </div>
                  ))}
                  <div className="combo-details-h6 mt-2">
                    Notes from Customer
                  </div>
                  <div className="cart-message">{item?.message}</div>
                  <hr />
                </div>
              ))}
            </>
          </div>
        </div>
      ))}
    </>
  )
}

export default MyOrders
