import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const comboApi = createApi({
  reducerPath: 'comboApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1' }),
  tagTypes: ['Combos', 'Combo', 'AdminCombos'],
  endpoints: (builder) => ({
    getComboDetails: builder.query({
      query: (id) => `/combos/${id}`,
      providesTags: ['Combo'],
    }),

    getAdminCombos: builder.query({
      query: () => `/admin/combos`,
      providesTags: ['AdminCombos'],
    }),

    getCombos: builder.query({
      query: () => `/combos`,
      providesTags: ['Combos'],
    }),

    createCombo: builder.mutation({
      query(body) {
        return {
          url: '/admin/combos',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['AdminCombos'],
    }),

    updateCombo: builder.mutation({
      query({ id, body }) {
        return {
          url: `/admin/combos/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Combo', 'AdminCombos'],
    }),

    deleteCombo: builder.mutation({
      query(id) {
        return {
          url: `/admin/combos/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['AdminCombos'],
    }),
  }),
})

export const {
  useGetComboDetailsQuery,
  useCreateComboMutation,
  useUpdateComboMutation,
  useDeleteComboMutation,
  useGetAdminCombosQuery,
  useGetCombosQuery,
} = comboApi
