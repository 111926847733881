import React, { useEffect, useState } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'
import MetaData from '../../layout/MetaData'
import AdminLayout from '../../layout/AdminLayout'
import { useCreateProductMutation } from '../../../redux/api/productsApi'
import { useGetAdminCategoriesQuery } from '../../../redux/api/categoriesApi'

const initialState = {
  name: '',
  price: '',
  description: '',
  category: '',
  stock: '',
}

const NewProduct = () => {
  const [values, setValues] = useState(initialState)

  const { name, price, description, category, stock } = values

  const [createProduct, { isLoading, error, isSuccess }] =
    useCreateProductMutation()

  const { data: getCategories, error: categoryError } =
    useGetAdminCategoriesQuery()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }
    if (categoryError) {
      toast.error(error?.data?.message)
    }

    if (isSuccess) {
      toast.success('Product created')
      window.location.reload()
    }
  }, [error, isSuccess, categoryError])

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    createProduct(values)
  }
  if (isLoading) return <Loader />
  return (
    <AdminLayout>
      <MetaData title={'Create new Product'} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">New Product</h2>

            <div className="row">
              <div className="mb-5 col">
                <label htmlFor="category_field" className="form-label">
                  {' '}
                  Category{' '}
                </label>
                <select
                  className="form-select"
                  id="category_field"
                  name="category"
                  value={category._id}
                  onChange={onChange}
                >
                  <option>Select One</option>
                  {getCategories?.categories.map((c) => (
                    <option key={c._id} value={c._id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="name_field" className="form-label">
                {' '}
                Name{' '}
              </label>
              <input
                type="text"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={onChange}
              />
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="price_field" className="form-label">
                  {' '}
                  Product Price{' '}
                </label>
                <input
                  type="text"
                  id="price_field"
                  className="form-control"
                  name="price"
                  value={price}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="description_field" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="description_field"
                rows="4"
                name="description"
                value={description}
                onChange={onChange}
              ></textarea>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="stock_field" className="form-label">
                  {' '}
                  Stock{' '}
                </label>
                <input
                  type="number"
                  id="stock_field"
                  className="form-control"
                  name="stock"
                  value={stock}
                  onChange={onChange}
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn w-100 py-2"
              disabled={isLoading}
            >
              {isLoading ? 'Creating...' : 'CREATE'}
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  )
}

export default NewProduct
