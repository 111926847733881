import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cartItems: localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : [],
}

export const cartSlice = createSlice({
  initialState,
  name: 'cartSlice',
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload

      const isItemExist = state.cartItems.find((i) => i.product === item.mealId)

      if (isItemExist) {
        state.cartItems = state.cartItems.map((i) =>
          i.product === isItemExist.product ? item : i
        )
      } else {
        state.cartItems = [...state.cartItems, item]
      }

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },
    removeFromCart: (state, action) => {
      state.cartItems = state?.cartItems?.filter(
        (i) => i.mealId !== action.payload
      )

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },
    clearCartItems: (state, action) => {
      localStorage.removeItem('cartItems')
      state.cartItems = []
    },
  },
})

export default cartSlice.reducer

export const { addToCart, removeFromCart, clearCartItems } = cartSlice.actions
