import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  combo: localStorage.getItem('combo')
    ? JSON.parse(localStorage.getItem('combo'))
    : [],
}

export const comboSlice = createSlice({
  initialState,
  name: 'comboSlice',
  reducers: {
    setCombo: (state, action) => {
      const item = action.payload

      const isItemExist = state.combo.find((i) => i.combo === item.combo)

      if (isItemExist) {
        state.combo = state.combo.map((i) =>
          i.combo === isItemExist.combo ? item : i
        )
      } else {
        state.combo = [...state.combo, item]
      }

      localStorage.setItem('combo', JSON.stringify(state.combo))
    },
    removeCombo: (state, action) => {
      state.combo = state?.combo?.filter((i) => i.product !== action.payload)

      localStorage.setItem('combo', JSON.stringify(state.combo))
    },
    clearCombo: (state, action) => {
      localStorage.removeItem('combo')
      state.combo = []
    },
  },
})

export default comboSlice.reducer

export const { setCombo, removeCombo, clearCombo } = comboSlice.actions
