import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1' }),
  tagTypes: ['Product', 'AdminProducts'],
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (params) => ({
        url: '/products',
        params: {
          page: params?.page,
          category: params?.category,
        },
      }),
    }),

    getProductDetails: builder.query({
      query: (id) => `/products/${id}`,
      providesTags: ['Product'],
    }),

    getAdminProducts: builder.query({
      query: () => `/admin/products`,
      providesTags: ['AdminProducts'],
    }),

    createProduct: builder.mutation({
      query(body) {
        return {
          url: '/admin/products',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['AdminProducts'],
    }),

    getFiLteredProducts: builder.mutation({
      query(body) {
        
        return {
          url: '/products/by/search',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Products'],
    }),



    updateProduct: builder.mutation({
      query({ id, body }) {
        return {
          url: `/admin/products/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Product', 'AdminProducts'],
    }),

    uploadProductImages: builder.mutation({
      query({ id, body }) {
        return {
          url: `/admin/products/${id}/upload_images`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Product'],
    }),

    deleteProductImage: builder.mutation({
      query({ id, body }) {
        return {
          url: `/admin/products/${id}/delete_image`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Product'],
    }),

    deleteProduct: builder.mutation({
      query(id) {
        return {
          url: `/admin/products/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['AdminProducts'],
    }),
  }),
})

export const {
  useGetProductsQuery,
  useGetFiLteredProductsMutation,

  useGetProductDetailsQuery,
  useGetAdminProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useUploadProductImagesMutation,
  useDeleteProductImageMutation,
  useDeleteProductMutation,
} = productApi
