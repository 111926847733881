import React, { useEffect, useState } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'

import { Link, useParams } from 'react-router-dom'
import MetaData from '../../layout/MetaData'

import AdminLayout from '../../layout/AdminLayout'
import {
  useOrderDetailsQuery,
  useUpdateOrderMutation,
} from '../../../redux/api/orderApi'

const ProcessOrder = () => {
  const [status, setStatus] = useState('')

  const params = useParams()
  const { data } = useOrderDetailsQuery(params?.id)
  const order = data?.order || {}

  const [updateOrder, { error, isSuccess }] = useUpdateOrderMutation()

  const { orderItems, user, orderStatus } = order

  useEffect(() => {
    if (orderStatus) {
      setStatus(orderStatus)
    }
  }, [orderStatus])

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }

    if (isSuccess) {
      toast.success('Order updated')
    }
  }, [error, isSuccess])

  const updateOrderHandler = (id) => {
    const data = { status }
    updateOrder({ id, body: data })
  }

  return (
    <AdminLayout>
      <MetaData title={'Process Order'} />
      <div className="row d-flex justify-content-around">
        <div className="col-12 col-lg-8 order-details">
          <h3 className="mt-5 mb-4">Order Details</h3>

          <table className="table table-striped table-bordered">
            <tbody>
              <tr>
                <th scope="row">Name</th>
                <td>{order?.user?.name}</td>
              </tr>
              <tr>
                <th scope="row">ID</th>
                <td>{order?._id?.substring(16)}</td>
              </tr>
              <tr>
                <th scope="row">Order Status</th>
                <td
                  className={
                    String(orderStatus).includes('Delivered')
                      ? 'greenColor'
                      : 'redColor'
                  }
                >
                  <b>{orderStatus}</b>
                </td>
              </tr>
            </tbody>
          </table>

          <h3 className="mt-5 my-4">Order Items:</h3>

          <hr />
          <div className="cart-item my-1">
            {orderItems?.map((item) => (
              <div className="row my-5">
                {`(${item?.quantity}) orders of (${item?.name})  `}
              </div>
            ))}
          </div>
          <hr />
        </div>
        <div className="col-12 col-lg-3 mt-5">
          <h4 className="my-4">Status</h4>

          <div className="mb-3">
            <select
              className="form-select"
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Received">Received</option>
              <option value="Processing">Processing</option>
              <option value="Ready For Pick-Up">Ready for Pick-up</option>
              <option value="Delivered">Delivered</option>
            </select>
          </div>

          <button
            className="btn btn-primary w-100"
            onClick={() => updateOrderHandler(order?._id)}
          >
            Update Status
          </button>
        </div>
      </div>
    </AdminLayout>
  )
}

export default ProcessOrder
