import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  indiviItems: localStorage.getItem('indiviItems')
    ? JSON.parse(localStorage.getItem('indiviItems'))
    : [],
}

export const indiviItemSlice = createSlice({
  initialState,
  name: 'indiviSlice',
  reducers: {
    setIndiviItem: (state, action) => {
      const item = action.payload
      console.log('slice', item)
      const isItemExist = state.indiviItems.find(
        (i) => i.iPProductId === item.iPProductId
      )

      if (isItemExist) {
        state.indiviItems = state.indiviItems.map((i) =>
          i.iPProductId === isItemExist.iPProductId ? item : i
        )
      } else {
        state.indiviItems = [...state.indiviItems, item]
      }

      localStorage.setItem('indiviItems', JSON.stringify(state.indiviItems))
    },
    removeIndiviItem: (state, action) => {
      state.indiviItems = state?.indiviItems?.filter(
        (i) => i.product !== action.payload
      )

      localStorage.setItem('indiviItems', JSON.stringify(state.indiviItems))
    },
    clearIndivi: (state, action) => {
      localStorage.removeItem('indiviItems')
      state.indiviItems = []
    },
  },
})

export default indiviItemSlice.reducer

export const { setIndiviItem, removeIndiviItem, clearIndivi } =
  indiviItemSlice.actions
