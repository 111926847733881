import React, { useEffect, useState } from 'react'
import MetaData from '../components/layout/MetaData'
import { useDispatch, useSelector } from 'react-redux'
import { removeFromCart } from '../redux/features/cartSlice'
import { caluclateOrderCost } from '../helpers/helpers'
import { toast } from 'react-hot-toast'
import { useStripeCheckoutMutation } from '../redux/api/orderApi'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
const Cart = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let orderId = uuidv4().substring(26)

  const { cartItems } = useSelector((state) => state.cart)
  console.log('cartItems', cartItems)
  const { itemsPrice, taxPrice, totalPrice } = caluclateOrderCost(cartItems)

  const [
    stripeCheckoutSession,
    { data: checkoutData, error: checkoutError, isLoading },
  ] = useStripeCheckoutMutation()

  useEffect(() => {
    if (checkoutData) {
      window.location.href = checkoutData?.url
    }

    if (checkoutError) {
      toast.error(checkoutError?.data?.message)
    }
  }, [checkoutData, checkoutError])

  const removeCartItemHandler = (mealId) => {
    dispatch(removeFromCart(mealId))
  }

  const submitHandler = (e) => {
    e.preventDefault()

    // Stripe Checkout
    const orderData = {
      orderItems: cartItems,
      itemsPrice,
      taxAmount: taxPrice,
      totalAmount: Number(totalPrice),
      quantity: 1,
      orderId,
      paymentMethod: 'Card',
      paymentInfo: {
        status: 'Paid',
      },
    }
    stripeCheckoutSession(orderData)
    // navigate('/payment')
  }

  return (
    <>
      <MetaData title={'Your Cart'} />
      Click Home Page to Continue Shopping
      {cartItems?.length === 0 ? (
        <h2 className="mt-5">Your Cart is Empty</h2>
      ) : (
        <>
          <div>
            <button
              type="button"
              id="cart_btn"
              className="btn btn-primary d-inline mt-2"
              // disabled={product.stock <= 0}
              onClick={submitHandler}
            >
              proceed to payment
            </button>
          </div>
          <div className="row d-flex justify-content-between">
            <div id="order_summary">
              <h2 className="mt-1">
                <span className="order-summary-values">
                  Your Cart has{' '}
                  {cartItems?.reduce(
                    (acc, item) => acc + item?.mealQuantity,
                    0
                  )}{' '}
                  meal(s)
                </span>
              </h2>
            </div>
            <hr />
            <div className="cart-card">
              <div className="cart-total-row">
                <span className="order-summary-values">
                  purchase order number: {orderId}
                </span>
                <span className="order-summary-values">
                  Cart-Subtotal: ${itemsPrice}
                </span>

                <span className="order-summary-values">
                  Cart-Tax @ 15%: ${taxPrice}
                </span>

                <span className="order-summary-values">
                  Cart-Total: ${totalPrice}
                </span>
              </div>
              {/* meal  */}

              {cartItems?.map((item, i) => (
                <div key={i}>
                  <div className="cart-meal-card ">
                    <div className="cart-header-row">
                      <div>{`${item?.name}'s meal #${item?.mealId} `}</div>
                      <div>{`Total is $${item?.mealTotal?.toFixed(2)}`}</div>
                    </div>

                    {/* combo */}
                    {item?.combo?.map((item1) => (
                      <>
                        <div className="cart-meal-row">
                          <div>{item1?.name}</div>
                          <div> cost is ${item1?.price?.toFixed(2)}</div>
                        </div>
                      </>
                    ))}
                    {/* comboItem */}
                    {item?.comboItems?.map((item2) => (
                      <div className="cart-meal-row">
                        <div>
                          {`${item2?.cPName} ${
                            item2?.cPQuantity
                          } extra's @ $${item2?.cPPrice?.toFixed(2)}`}
                        </div>
                        <div>
                          {` Cost is
                      $${item2?.totalCost?.toFixed(2)}`}
                        </div>
                      </div>
                    ))}
                    {/* products */}
                    {item?.indiviItems?.map((item3) => (
                      <div className="cart-meal-row">
                        <div>
                          {` ${item3?.iPQuantity} order ${item3?.iPName}

                       @ 
                      $${item3?.iPPrice?.toFixed(2)}`}
                        </div>
                        <div>{`cost is $${item3?.totalCost?.toFixed(2)}`}</div>
                      </div>
                    ))}
                    <div className="combo-details-h6 mt-2">
                      Notes from Customer
                    </div>
                    <div className="cart-message">{item?.message}</div>
                    <button
                      id="cart_btn"
                      className="btn btn-primary d-inline ms-4"
                      onClick={() => removeCartItemHandler(item?.mealId)}
                    >
                      Remove Meal From Cart
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="fw-bold text-capitalize py-2">
            the next page belongs to the credit card processing company
            "stripe". there is an arrow on their form, somewhere top left, that
            when clicked will return to you to this page, or use the back arrow
            on your browser. If you have used stripe befoe, you would be aware
            that with their "link" services, entering your phone number or email
            you get a code from them to process the payment. If you choose not
            to use "link", click the button "pay withou link" and manually enter
            your card number.
            <br />
          </div>
        </>
      )}
    </>
  )
}

export default Cart
