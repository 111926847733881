import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const AdminHeader = ({ menuItems }) => {
  const location = useLocation()

  const [activeMenuItem, setActiveMenuItem] = useState(location.pathname)

  const handleMenuItemClick = (menuItemUrl) => {
    setActiveMenuItem(menuItemUrl)
  }

  return (
    <>
      {/* Navigation Bar  */}
      <nav className="navbar navbar-expand-lg bg-body-tertiary d-flex border  justify-content-between">
        {/* <div className="container-fluid"> */}
        <Link className="navbar-brand" to="/admin/dashboard">
          Admin Dashboard
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          {menuItems?.map((menuItem, index) => (
            <Link
              key={index}
              to={menuItem.url}
              className={` text-capitalize p-2 m-1 fw-bold list-group-item list-group-item-action ${
                activeMenuItem.includes(menuItem.url) ? 'active' : ''
              }`}
              onClick={() => handleMenuItemClick(menuItem.url)}
              aria-current={
                activeMenuItem.includes(menuItem.url) ? 'true' : 'false'
              }
            >
              {menuItem?.name}
            </Link>
          ))}
        </div>
        {/* </div> */}
      </nav>
      {/* End of Navigation Bar */}
    </>
  )
}
export default AdminHeader
