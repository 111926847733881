import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import Loader from '../../layout/Loader'
import MetaData from '../../layout/MetaData'
import AdminLayout from '../../layout/AdminLayout'
import { useCreateCategoryMutation } from '../../../redux/api/categoriesApi'

const NewCategory = () => {
  const [category, setCategory] = useState({
    name: '',
  })

  const { name } = category

  const [createCategory, { isLoading, error, isSuccess }] =
    useCreateCategoryMutation()

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
    }

    if (isSuccess) {
      toast.success('Category created')
      window.location.reload()
    }
  }, [error, isSuccess])

  const onChange = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    createCategory(category)
  }

  return (
    <AdminLayout>
      <MetaData title={'Create new Category'} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">New Category</h2>
            <div className="mb-3">
              <label htmlFor="name_field" className="form-label">
                {' '}
                Name{' '}
              </label>
              <input
                type="text"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={onChange}
              />
            </div>

            <button
              type="submit"
              className="btn w-100 py-2"
              disabled={isLoading}
            >
              {isLoading ? 'Creating...' : 'CREATE'}
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  )
}

export default NewCategory
