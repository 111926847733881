import React, { useEffect, useState } from 'react'
import Loader from '../../layout/Loader'
import { toast } from 'react-hot-toast'

import MetaData from '../../layout/MetaData'
import AdminLayout from '../../layout/AdminLayout'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetProductDetailsQuery,
  useUpdateProductMutation,
} from '../../../redux/api/productsApi'
import { useGetAdminCategoriesQuery } from '../../../redux/api/categoriesApi'

const initialState = {
  name: '',
  price: 0,
  description: '',
  category: '',
  stock: 0,
}

const UpdateProduct = () => {
  const [values, setValues] = useState(initialState)
  const [selectedCategory, setSelectedCategory] = useState('')

  const navigate = useNavigate()
  const params = useParams()

  const { name, description, price, category, stock } = values

  const { data, error: productError } = useGetProductDetailsQuery(params?.id)

  let cName = data?.product?.categoryName
  

  const [updateProduct, { isLoading, error, isSuccess }] =
    useUpdateProductMutation()

  const { data: getCategories, error: categoryError } =
    useGetAdminCategoriesQuery()

  useEffect(() => {
    if (data?.product) {
      setValues({
        name: data?.product?.name,
        price: data?.product?.price,
        description: data?.product?.description,
        stock: data?.product?.stock,
        category: data?.product?.category,
      })
    }
    if (error) {
      toast.error(error?.data?.message)
    }
    if (productError) {
      toast.error(error?.data?.message)
    }
    if (categoryError) {
      toast.error(error?.data?.message)
    }

    if (isSuccess) {
      toast.success('Product updated')
      navigate('/admin/products')
    }
  }, [error, isSuccess, productError, categoryError, data])

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    values.category = selectedCategory ? selectedCategory : values.category
    updateProduct({ id: params?.id, body: values })
  }
  if (isLoading) return <Loader />
  return (
    <AdminLayout>
      <MetaData title={'Update Product'} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">Update Product</h2>
            <div className="row">
              <div className="mb-5 col">
                <label htmlFor="category_field" className="form-label">
                  {' '}
                  If Changed, Until Updated, Category is {cName}
                </label>
                <select
                  className="form-select"
                  id="category_field"
                  name="category"
                  value={selectedCategory ? selectedCategory : category._id}
                  onChange={onChange}
                >
                  <option>Select One</option>
                  {getCategories?.categories.map((c) => (
                    <option key={c._id} value={c._id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  {' '}
                  Name{' '}
                </label>
                <input
                  type="text"
                  id="name_field"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={onChange}
                />
              </div>
            </div>{' '}
            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="price_field" className="form-label">
                  {' '}
                  Price{' '}
                </label>
                <input
                  type="text"
                  id="price_field"
                  className="form-control"
                  name="price"
                  value={price}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <label htmlFor="description_field" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="description_field"
                  rows="4"
                  name="description"
                  value={description}
                  onChange={onChange}
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="stock_field" className="form-label">
                  {' '}
                  Stock{' '}
                </label>
                <input
                  type="number"
                  id="stock_field"
                  className="form-control"
                  name="stock"
                  value={stock}
                  onChange={onChange}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn w-100 py-2"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'UPDATE'}
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  )
}

export default UpdateProduct
